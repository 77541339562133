import React, { lazy } from "react";
import { createRoot } from "react-dom/client";

import Clarity from "@microsoft/clarity";
import * as Sentry from "@sentry/react";
import { ConfigProvider } from "antd";
import { LDProvider } from "launchdarkly-react-client-sdk";
import { Provider } from "react-redux";

import store from "./redux/store";
import { NewRelic } from "./utils/NewRelic";

const container = document.getElementById("root")!;
const root = createRoot(container);
const App = lazy(() => import("./App"));

const environment = process.env.REACT_APP_ENV;
if (environment === "production" || environment === "staging") {
    Sentry.init({
        dsn: "https://78a6ee7791278cae21a47129a1a0303c@o4506547685097472.ingest.us.sentry.io/4507108231872512",
        integrations: [
            new Sentry.BrowserTracing({
                tracePropagationTargets: ["localhost", /^https:\/\/taxzap\.com/, /^https:\/\/taxzap\.online/],
            }),
        ],
        environment: environment,
        tracesSampleRate: 1.0,
    });
}
if (environment !== "development") {
    console.log("Initialising Clarity on Environment", process.env.REACT_APP_ENV);
    const projectId = process.env.REACT_APP_CLARITY_PROJECT_ID;
    Clarity.init(projectId!);
}

NewRelic.init();

const ldOptions = {
    sendEvents: false,
};

root.render(
    <ConfigProvider
        theme={{
            token: {
                colorPrimary: "#2EBB49",
                fontFamily: "Poppins, sans-serif",
            },
            components: {
            },
        }}
    >
        <LDProvider clientSideID={process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID!} options={ldOptions}>
            <Provider store={store}>
                <App />
            </Provider>
        </LDProvider>
    </ConfigProvider>
);
if ("serviceWorker" in navigator) {
    window.addEventListener("load", () => {
        navigator.serviceWorker.register("./service-worker.js")
            .then(
                registration => {
                    console.log("ServiceWorker registration successful with scope: ", registration.scope);
                },
                err => {
                    console.log("ServiceWorker registration failed: ", err);
                }
            );
    });
}
